import { countBy, flatten, sumBy } from 'lodash';
import React from 'react';

export * from './api';
export * from './auth';

export function isDebug() {
  return (
    new URLSearchParams(window.location.search).has('debug') ||
    window.location.hostname === 'localhost'
  );
}

export function encodeURISciName(s: string) {
  return s
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9_.-]/g, '');
}

export const months = {
  map: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
  },
  list: [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ],
};

export function squeezeBloomtime(
  // list of months like [['jul','aug'], ['jul'], []]
  bloomtime: string[][]
): {
  unknown: number;
  months: {
    monthShort: string;
    month: string;
    blooming: number;
    letter: string;
  }[];
} {
  // Usually the only months represented are April through October.
  // Show only that range but expand it if necessary.
  const defaultEarliest = 3; // April
  const defaultLatest = 9; // October
  const flat = flatten(bloomtime);
  const bloomnums = flat.map((b) => months.list.indexOf(b));
  const counts = countBy(bloomnums);
  const earliest =
    flat.length > 0
      ? Math.min(defaultEarliest, Math.min.apply(null, bloomnums))
      : defaultEarliest;
  const latest =
    flat.length > 0
      ? Math.max(defaultLatest, Math.max.apply(null, bloomnums))
      : defaultLatest;
  return {
    unknown: sumBy(bloomtime, (x) => (x.length === 0 ? 1 : 0)),
    months: months.list.slice(earliest, latest + 1).map((mon, i) => ({
      monthShort: mon,
      month: (months.map as { [key: string]: string })[mon],
      blooming: counts[i + earliest] || 0,
      letter: mon[0],
    })),
  };
}

export function useThrottle<T>(value: T, interval = 500): T {
  const [throttledValue, setThrottledValue] = React.useState(value);
  const lastUpdated = React.useRef(null as number | null);

  React.useEffect(() => {
    const now = Date.now();

    if (lastUpdated.current && now >= lastUpdated.current + interval) {
      lastUpdated.current = now;
      setThrottledValue(value);
    } else {
      const id = window.setTimeout(() => {
        lastUpdated.current = now;
        setThrottledValue(value);
      }, interval);

      return () => window.clearTimeout(id);
    }
    return undefined;
  }, [value, interval]);

  return throttledValue;
}

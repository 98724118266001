import { ApiRequestOptions } from '../client/core/ApiRequestOptions';
import { OpenAPI } from '../client/core/OpenAPI';
import { getQueryString } from '../client/core/request';
import { BACKEND_URL } from '../config';

// TODO: this is unneeded
export const getMessage = async () => {
  const response = await fetch(BACKEND_URL);

  const data = await response.json();

  if (data.message) {
    return data.message;
  }

  return Promise.reject(new Error('Failed to get message from backend'));
};

export const getUrl = (options: ApiRequestOptions): string => {
  const config = OpenAPI;
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (
        options.path &&
        Object.prototype.hasOwnProperty.call(options.path, group)
      ) {
        return encoder(String(options.path[group]));
      }
      return substring;
    });

  const url = `${config.BASE}${path}`;
  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }
  return url;
};

import AddIcon from '@mui/icons-material/Add';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  Paper,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { useImmer } from 'use-immer';
import { ApiError, Taxon, TaxonService } from '../client';
import { useStore } from '../store';
import { encodeURISciName } from '../utils/index';
import { BasketDrawer } from './BasketDrawer';

const showCruft = false;

export const Condition: FC = () => {
  const filterInitial = {
    v: 2, // schema version
    sun: {
      enabled: false,
      hoursOfSun: 4, // <- cruft
      sun_allowed: [],
    },
    size: {
      enabled: false,
      // Note: These are indices into SIZESLIDER_MARKS.
      height_min: 0,
      height_max: SIZESLIDER_MARKS.length - 1,
      width_min: 0,
      width_max: SIZESLIDER_MARKS.length - 1,
    },
    water: {
      enabled: false,
      water_allowed: [],
    },
    soil_texture: {
      enabled: false,
    },
    layer: {
      enabled: false,
    },
  };
  const [queryFilterStr, setQueryFilter] = useQueryState(
    'filter',
    JSON.stringify(filterInitial)
  );
  const queryFilter = (() => {
    try {
      return JSON.parse(queryFilterStr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('json parse error of filter query');
      return filterInitial;
    }
  })();
  const [state, setState] = useImmer({
    filter: queryFilter.v === filterInitial.v ? queryFilter : filterInitial,
  });

  useEffect(() => {
    setQueryFilter(JSON.stringify(state.filter, null, 0));
  }, [state.filter, setQueryFilter]);

  const fetchFilterPayload = {
    heightMin: state.filter.size.enabled
      ? SIZESLIDER_LOOKUP(state.filter.size.height_min)
      : undefined,
    heightMax: state.filter.size.enabled
      ? SIZESLIDER_LOOKUP(state.filter.size.height_max)
      : undefined,
    widthMin: state.filter.size.enabled
      ? SIZESLIDER_LOOKUP(state.filter.size.width_min)
      : undefined,
    widthMax: state.filter.size.enabled
      ? SIZESLIDER_LOOKUP(state.filter.size.width_max)
      : undefined,
    sunAllowed: state.filter.sun.enabled
      ? state.filter.sun.sun_allowed.join(',')
      : undefined,
    waterAllowed: state.filter.water.enabled
      ? state.filter.water.water_allowed.join(',')
      : undefined,
  };

  const query = useQuery({
    queryKey: ['taxonFilterApiV1TaxonFilterGet', fetchFilterPayload],
    queryFn: () =>
      TaxonService.taxonFilterApiV1TaxonFilterGet(fetchFilterPayload),
  });

  return (
    <Grid container direction="column">
      <AppBar position="static" sx={{ zIndex: 2000 }}>
        <Toolbar>
          <Link to="/" underline="none" color="inherit" component={RouterLink}>
            <Typography variant="h6" noWrap component="div">
              Site Conditions
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Grid item container direction="row">
        <Grid
          item
          /* Filter side */
          minWidth={650}
          sx={{
            flex: 1,
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
          }}
        >
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography>
              Search for plants based on your site conditions.
            </Typography>

            {/* SUN */}
            <FilterSection
              label={
                !(state.filter.sun.enabled && showCruft)
                  ? 'Sun'
                  : `Sun - ${(() => {
                      switch (true) {
                        case state.filter.sun.hoursOfSun <= 4:
                          return 'Full shade';
                        case state.filter.sun.hoursOfSun <= 7:
                          return 'Full sun to partial shade';
                        default:
                          return 'Full sun';
                      }
                    })()}`
              }
              enabled={state.filter.sun.enabled}
              onClickHeader={() =>
                setState((draft) => {
                  draft.filter.sun.enabled = !draft.filter.sun.enabled;
                })
              }
            >
              <Box>
                <ToggleButtonGroup
                  value={state.filter.sun.sun_allowed}
                  onChange={(ev, alignment) =>
                    setState((draft) => {
                      draft.filter.sun.sun_allowed = alignment;
                    })
                  }
                >
                  <ToggleButton value="full_sun">Full sun</ToggleButton>
                  <ToggleButton value="partial">Partial</ToggleButton>
                  <ToggleButton value="full_shade">Full shade</ToggleButton>
                  <ToggleButton value="dappled">Dappled</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {showCruft && (
                <>
                  <InputLabel>Hours of sunlight</InputLabel>
                  {/* TODO there's a nuance missing here between:
              - specifying the actual condition for one site
              - specifying the range of available conditions in a multi-condition space */}
                  <Box sx={{ width: 600 }}>
                    <Slider
                      onChange={(_, val) => {
                        setState((draft) => {
                          draft.filter.sun.hoursOfSun = val as number;
                        });
                      }}
                      value={state.filter.sun.hoursOfSun}
                      valueLabelDisplay="auto"
                      getAriaValueText={(val: number) => `${val}'`}
                      min={0}
                      max={17}
                      sx={{
                        '& .MuiSlider-track': (() => {
                          switch (true) {
                            case state.filter.sun.hoursOfSun <= 4:
                              return {};
                            case state.filter.sun.hoursOfSun <= 7:
                              return { background: 'yellow' };
                            default:
                              return { background: 'orange' };
                          }
                        })(),
                        '& .MuiSlider-thumb': {
                          [`&:nth-of-type(${1}n)`]: (() => {
                            switch (true) {
                              case state.filter.sun.hoursOfSun <= 4:
                                return {};
                              case state.filter.sun.hoursOfSun <= 7:
                                return { background: 'yellow' };
                              default:
                                return { background: 'orange' };
                            }
                          })(),
                        },
                        // '& .MuiSlider-mark': {
                        //   background: 'none',
                        // },
                        // '& .MuiSlider-rail': {
                        //   background: `linear-gradient(to right, red 0% 30%, yellow 20% 40%, green 50% 100%)`,
                        // },
                        // '& .MuiSlider-valueLabel': {},
                      }}
                    />
                  </Box>
                  <InputLabel>Solstice</InputLabel>
                  <SunSlider />
                  <InputLabel>Equinox</InputLabel>
                  <SunSlider />
                </>
              )}
            </FilterSection>

            {/* WATER */}
            <FilterSection
              label="Water"
              enabled={state.filter.water.enabled}
              onClickHeader={() =>
                setState((draft) => {
                  draft.filter.water.enabled = !draft.filter.water.enabled;
                })
              }
            >
              <Box>
                <ToggleButtonGroup
                  value={state.filter.water.water_allowed}
                  onChange={(ev, alignment) =>
                    setState((draft) => {
                      draft.filter.water.water_allowed = alignment;
                    })
                  }
                >
                  <ToggleButton value="dry">Dry</ToggleButton>
                  <ToggleButton value="medium">Medium</ToggleButton>
                  <ToggleButton value="wet">Wet</ToggleButton>
                  <ToggleButton value="water_plant">Water plant</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </FilterSection>

            <FilterSection
              label="Size"
              enabled={state.filter.size.enabled}
              onClickHeader={() =>
                setState((draft) => {
                  draft.filter.size.enabled = !draft.filter.size.enabled;
                })
              }
            >
              <InputLabel>Height</InputLabel>
              <SizeSlider
                defaultMin={state.filter.size.height_min}
                defaultMax={state.filter.size.height_max}
                onChange={(min, max) =>
                  setState((draft) => {
                    draft.filter.size.height_min = min;
                    draft.filter.size.height_max = max;
                  })
                }
              />
              <InputLabel>Width</InputLabel>
              <SizeSlider
                defaultMin={state.filter.size.width_min}
                defaultMax={state.filter.size.width_max}
                onChange={(min, max) =>
                  setState((draft) => {
                    draft.filter.size.width_min = min;
                    draft.filter.size.width_max = max;
                  })
                }
              />
            </FilterSection>
            {showCruft && (
              <>
                <FilterSection
                  label="Water"
                  enabled={state.filter.water.enabled}
                  onClickHeader={() =>
                    setState((draft) => {
                      draft.filter.water.enabled = !draft.filter.water.enabled;
                    })
                  }
                >
                  <Box>
                    <ToggleButtonGroup>
                      <ToggleButton value>Dry soil</ToggleButton>
                      <ToggleButton value>Mesic</ToggleButton>
                      <ToggleButton value>Moist</ToggleButton>
                      <ToggleButton value>Wet or boggy</ToggleButton>
                      <ToggleButton value>Water plants</ToggleButton>
                      <ToggleButton value>Well-drained</ToggleButton>
                      <ToggleButton value>Poorly drained?</ToggleButton>
                      <ToggleButton value>Tolerates drought</ToggleButton>
                    </ToggleButtonGroup>
                    <SearchOffIcon />
                  </Box>
                </FilterSection>
                <FilterSection
                  label="Soil texture"
                  enabled={state.filter.soil_texture.enabled}
                  onClickHeader={() =>
                    setState((draft) => {
                      draft.filter.soil_texture.enabled =
                        !draft.filter.soil_texture.enabled;
                    })
                  }
                >
                  <Box>
                    <ToggleButtonGroup>
                      <ToggleButton value>Sandy</ToggleButton>
                      <ToggleButton value>Loam</ToggleButton>
                      <ToggleButton value>Clay</ToggleButton>
                      <ToggleButton value>Silty</ToggleButton>
                    </ToggleButtonGroup>
                    <SearchOffIcon />
                  </Box>
                </FilterSection>
                <FilterSection
                  label="Forest layer"
                  enabled={state.filter.layer.enabled}
                  onClickHeader={() =>
                    setState((draft) => {
                      draft.filter.layer.enabled = !draft.filter.layer.enabled;
                    })
                  }
                >
                  <Box>
                    <ToggleButtonGroup>
                      <ToggleButton value={false}>Tree</ToggleButton>
                      <ToggleButton value={false}>Shrub</ToggleButton>
                      <ToggleButton value={false}>Herb</ToggleButton>
                      <ToggleButton value={false}>Groundcover</ToggleButton>
                      <ToggleButton value={false}>Vine</ToggleButton>
                    </ToggleButtonGroup>
                    <SearchOffIcon />
                  </Box>
                </FilterSection>
                <h6>Resistance</h6>
                <ToggleButtonGroup>
                  <ToggleButton value={false}>Drought</ToggleButton>
                  <ToggleButton value={false}>Wet feet</ToggleButton>
                  <ToggleButton value={false}>Salt</ToggleButton>
                  <ToggleButton value={false}>Deer browse</ToggleButton>
                </ToggleButtonGroup>
                <h6>Priority</h6>
                <ToggleButtonGroup>
                  <ToggleButton value>Keystone species</ToggleButton>
                  <ToggleButton value>Native</ToggleButton>
                  <ToggleButton value={false}>Edible</ToggleButton>
                  <ToggleButton value={false}>Bloom coverage</ToggleButton>
                </ToggleButtonGroup>
              </>
            )}
          </Paper>
        </Grid>

        <Grid
          // Results side
          item
          component="main"
          minWidth={320}
          sx={{
            bgcolor: 'background.default',
            paddingX: 3,
            flex: 10000,
          }}
        >
          <Grid container>
            {query.isPending && (
              <Grid item>
                <CircularProgress />
              </Grid>
            )}
            {query.error && (
              <Paper sx={{ marginBottom: 2 }}>
                <Grid container sx={{ color: 'red' }}>
                  <Grid item sx={{ mx: 1 }}>
                    {(query.error as ApiError).status}
                  </Grid>
                  <Grid item sx={{ mx: 1 }}>
                    {(query.error as ApiError).name}
                  </Grid>
                  <Grid item sx={{ mx: 1 }}>
                    {(query.error as ApiError).message}
                  </Grid>
                  <Grid item sx={{ mx: 1 }}>
                    {(query.error as ApiError).url}
                  </Grid>
                </Grid>
              </Paper>
            )}
            {query.isSuccess && (
              <>
                <p>
                  Results:{' '}
                  {query.data.length <= OMIT_RESULTS_LIMIT
                    ? query.data.length
                    : `showing ${OMIT_RESULTS_LIMIT} of ${query.data.length}`}
                </p>
                {query.data.slice(0, OMIT_RESULTS_LIMIT).map((r) => (
                  <SearchResult key={r.name_sci} r={r} />
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <BasketDrawer />
    </Grid>
  );
};

const OMIT_RESULTS_LIMIT = 50;

const SunSlider: FC = () => {
  const marks = [
    4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ].map((n, i) => ({
    value: i,
    time: n,
    label: n,
  }));
  const max: number = marks.at(-1)?.value || 0;
  const [value, setValue] = useState<number[]>([5, 11]);

  const minDistance = 0;

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minDistance);
        setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue([clamped - minDistance, clamped]);
      }
    } else {
      setValue(newValue as number[]);
    }
  };

  return (
    <Box sx={{ width: 600 }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        marks={marks}
        min={marks[0].value}
        max={max}
        step={null}
      />
    </Box>
  );
};

const SIZESLIDER_MARKS = [1, 2, 3, 5, 10, 20, 30, 40, 50, 60, 70, 80, 100, 150]; // ft
const SIZESLIDER_LOOKUP = (i: number) => {
  if (i <= 0) return 0;
  if (i >= SIZESLIDER_MARKS.length - 1) return 9999;
  return SIZESLIDER_MARKS[i] * 12; // inches
};

const SizeSlider: FC<{
  onChange?: (min: number, max: number) => void;
  defaultMin?: number;
  defaultMax?: number;
}> = ({ onChange, defaultMin, defaultMax }) => {
  const marks = SIZESLIDER_MARKS.map((n, i) => ({
    value: i,
    ft: n,
    label: (() => {
      switch (n) {
        case 1:
          return `≤ ${n}`;
        case 150:
          return `≥ ${n}`;
        default:
          return `${n}'`;
      }
    })(),
  }));
  const max: number = marks.at(-1)?.value || 0;
  const [value, setValue] = useState<number[]>([
    defaultMin || marks[0].value,
    defaultMax || max,
  ]);

  const minDistance = 1;

  const handleChange = (
    event: Event,
    draftValue: number | number[],
    activeThumb: number
  ) => {
    let newValue = draftValue as number[];
    if (Array.isArray(draftValue)) {
      if (draftValue[1] - draftValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(draftValue[0], max - minDistance);
          newValue = [clamped, clamped + minDistance];
          setValue(newValue);
        } else {
          const clamped = Math.max(draftValue[1], minDistance);
          newValue = [clamped - minDistance, clamped];
          setValue(newValue);
        }
      } else {
        setValue(newValue);
      }
    }
    onChange?.(newValue[0], newValue[1]);
  };

  return (
    <Box sx={{ width: 600 }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        getAriaValueText={(val: number) => `${val}'`}
        marks={marks}
        min={marks[0].value}
        max={max}
        step={null}
        disableSwap
      />
    </Box>
  );
};
SizeSlider.defaultProps = {
  onChange: undefined,
  defaultMin: undefined,
  defaultMax: undefined,
};

const FilterSection: FC<{
  label: string;
  enabled: boolean;
  onClickHeader: () => void;
  children: any;
}> = ({ onClickHeader, label, enabled, children }) => {
  return (
    <>
      <h6>
        <Button onClick={onClickHeader}>{label}</Button>
      </h6>
      {enabled && children}
    </>
  );
};

const SearchResult: FC<{
  r: Taxon;
}> = ({ r }) => {
  const store = useStore((state) => state);
  return (
    <Grid item sx={{ width: '100%', marginBottom: 3 }}>
      <Link
        to={`/taxon/${encodeURISciName(r.name_sci || '')}`}
        underline="none"
        component={RouterLink}
      >
        <Card
          sx={{
            border: r.native === 'y' ? 2 : undefined,
            borderColor: 'green',
          }}
        >
          <CardActionArea>
            <CardContent>
              <Grid container direction="row" spacing={2}>
                <ListItemText
                  primary={r.name_sci}
                  secondary={r.name_comm}
                  sx={{
                    paddingLeft: 2,
                  }}
                />
                <Grid item>
                  {r.rank === 'genus' && (
                    <Chip label="Genus" color="primary" sx={{ marginX: 1 }} />
                  )}
                  {r.rank === 'family' && (
                    <Chip
                      label="Family"
                      color="secondary"
                      sx={{ marginX: 1 }}
                    />
                  )}
                </Grid>
                {/* <Grid item>
                  <Box>H {r.filter_status?.height}</Box>
                </Grid>
                <Grid item xs>
                  <Box>W {r.filter_status?.width}</Box>
                </Grid> */}
                <Grid item>
                  {r.edible && parseInt(r.edible, 10) > 0 && (
                    <>
                      <RestaurantIcon /> {r.edible}
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      store.pickTaxon(r.name_sci || '');
                    }}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};
